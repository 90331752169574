<template>
  <div>
    <ul class="clear tabs">
      <li class="tab-item"
          :class="{'tab-item-current':current==item.type}"
          @click="changeTab(item.type)"
          :key="item.type"
          v-for="item in types">
        <span>{{item.name}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="tab-content">
      <div class="question-box">
        <div v-for="(item,index) in list"
             :key="index"
             class="item">
          <div @click="QuestionDetail(item)">
            <div class="question-item">
              <div class="question-item-left">
                <img :src="item.avatar" />
              </div>
              <div class="question-item-right">
                <h3 class="question-item-name">
                  {{item.nickname}}
                </h3>
                <span class="question-item-created_at">{{item.created_at}}</span>
              </div>
            </div>
            <div class="question_content">
              <p class="question-item-title">{{item.title}}</p>
              <div class="question-item-content">
                <img v-if="item.images" :src="stringToArray(item.images)[0]"
                     @click.stop="showPreview(stringToArray(item.images),index)" />
                <p v-html="item.content"></p>
              </div>
              <div class="question-item-operate">
                <span>
                  <img src="@/assets/images/news/read-count@3x.png" />
                  {{item.browse_number}}
                </span>
                <span>
                  <img src="@/assets/images/news/comment@3x.png" />
                  {{item.answer_number}}
                </span>
                <span @click.stop="clickLike(item)">
                  <img v-if="!item.is_like"
                       src="@/assets/images/news/dianzan@3x.png" />
                  <img v-else
                       src="@/assets/images/news/dianzan_active.png">
                  {{item.like_number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Empty v-if="list.length==0"
               class="empty"
               title="暂无回答！" />
        <el-pagination background
                       @current-change="handleCurrentChange"
                       :current-page="page"
                       layout="prev, pager, next, total, jumper"
                       :total="total"
                       v-if="list.length>0"></el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="isShowImageDialog"
               @closed="clearImg"
               :modal-append-to-body="false"
               append-to-body>
      <PreviewImg :imgList="prviewList"
                  :imgIndex="1"></PreviewImg>
    </el-dialog>
  </div>
</template>
<script>
import PreviewImg from "../../components/PreviewImg"

export default {
  name: "MemberQuestion",
  components: {
    PreviewImg
  },
  data () {
    return {
      types: [
        { type: 'myAsk', name: '我的提问' },
        { type: 'myCircusee', name: '我的围观' },
        { type: 'myCollection', name: '我的收藏' }
      ],
      current: 'myAsk',
      list: [],
      total: 0,
      page: 1,
      empty_type: {
        name: '暂无提问'
      },
      isShowImageDialog: false,
      prviewList: []
    };
  },
  async created () {
    this.getList();
  },
  methods: {
    changeTab (type) {
      this.page = 1;
      this.current = type;
      this.getList();
    },
    QuestionDetail (item) {
      // type 1:普通问答 2：课程问答 3： 题目问答 （跳转到带有题目与解析的页面）
      if (item.type == 3) {
        this.$router.push({ name: 'MemberQuestionExamDetail', query: { id: item.question_id, preview: 1 } })
      } else {
        this.$router.push({ name: 'QuestionShow', params: { id: item.id } });
      }
    },
    getList () {
      if (this.current == 'myAsk') {
        this.empty_type.name = '暂无提问';
      } else if (this.index == 'myCircusee') {
        this.empty_type.name = '暂无围观回答';
      } else if (this.index == 'myCollection') {
        this.empty_type.name = '暂无收藏回答';
      }
      this.$http.get(`/api/app/ask/${this.current}`, { page: this.page, limit: 10 }).then((res) => {
        this.list = res.list?res.list:[];
        this.total = res.total;
      });
    },
    handleCurrentChange (page) {
      this.page = page;
      this.getList();
    },
    stringToArray (str) {
      if (str == "" || str == null || str == undefined) {
        return [];
      } else {
        let arr = str.split(",");
        return arr;
      }
    },
    clearImg () {
      this.isShowImageDialog = false;
    },
    showPreview (list, index) {
      this.prviewList = list;
      this.isShowImageDialog = true;
    },
    clickLike (item) {
      let params = { ask_id: item.id, comment_id: 0, user_type: 1 };
      this.$pub.clickLike(params).then((res) => {
        if (item.is_like == 0) {
          item.is_like = 1;
          item.like_number++;
        } else {
          item.is_like = 0;
          item.like_number--;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/member-question.scss";
</style>
